

.header-box {
    background-color: cornflowerblue;
    margin: 10px;
    padding: 3px;
    color: white;
    display: flex;
}

.header-text {
    margin-left: 5px;
    flex-grow: 1;
}

.header-button {
    margin-left: 3px;
}