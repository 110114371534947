.no-caret:after {
    display: none;
}

.inbtn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;    
}

.room-item-name {
    margin: 7px 0px 0px 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}