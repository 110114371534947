@import url(https://spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: slategray !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 575px) {
  body {
    zoom: 90%
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
}
body {
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Spoqa Han Sans Neo', sans-serif;
}

h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}

body, html, .App, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 300px;
  margin: 40px auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 30px 25px 35px 25px;
  border-radius: 15px;
  transition: all .3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}


.header-box {
    background-color: cornflowerblue;
    margin: 10px;
    padding: 3px;
    color: white;
    display: flex;
}

.header-text {
    margin-left: 5px;
    flex-grow: 1;
}

.header-button {
    margin-left: 3px;
}
.room-box {
    background-color: cornflowerblue;
    margin: 10px;
    padding: 3px 8px;
    color: white;
}

.header-text {
    margin-left: 5px;
    flex-grow: 1;
}

.header-button {
    margin-left: 3px;
}
.no-caret:after {
    display: none;
}

.inbtn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;    
}

.room-item-name {
    margin: 7px 0px 0px 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

